<template>
  <div class="sanDongContainer">
    <Title></Title>
    <div class="banner">
    </div>
    <div class="schoolDesc">
      <div class="mianbaoxie">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" ><span style="color:#D70C0C;font-weight:400">首页</span></el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/typicalCase/typeCase'}"><span style="color:#D70C0C;font-weight:400">典型案例</span></el-breadcrumb-item>
        <el-breadcrumb-item>山东大学</el-breadcrumb-item>
      </el-breadcrumb>
      </div>
      <h4 class="descH4">高校简介</h4>
      <span class="line"></span>
      <p
        style="
          font-size: 16px;
          font-weight: 400;
          color: #222;
          line-height: 32px;
          width: 1100px;
          text-align: left;
          padding-top: 40px;
        "
      >
        山东大学是一所历史悠久、学科齐全、实力雄厚、特色鲜明的教育部直属重点综合性大学，在国内外具有重要影响，2017年顺利迈入世界一流大学建设高校（A类）行列。
      </p>
      <p
        style="
          font-size: 16px;
          font-weight: 400;
          color: #222;
          line-height: 32px;
          width: 1100px;
          text-align: left;
        "
      >
        山东大学前身是1901年创办的山东大学堂，被誉为中国近代高等教育起源性大学。其医学学科起源于1864年，开启近代中国高等医学教育之先河。从诞生起，学校先后历经了山东大学堂、国立青岛大学、国立山东大学、山东大学以及由原山东大学、山东医科大学、山东工业大学三校合并组建的新山东大学等几个历史发展时期。120年来，山东大学始终秉承“为天下储人才，为国家图富强”的办学宗旨，深入践行“学无止境，气有浩然”的校训精神，踔厉奋发，薪火相传，积淀形成了“崇实求新”的校风，培养了60余万各类人才，为国家和区域经济社会发展作出了重要贡献。
      </p>
    </div>
    <div class="construction center">
      <h4 class="descH4">建设挑战</h4>
      <span class="line"></span>
      <div class="constructionDesc">
        <ThreeCard
          :threeCard="constructionDesc"
          class="constructionDescCard"
        ></ThreeCard>
      </div>
    </div>
    <div class="step center">
      <ShowCard :cardContent="step" class="technicalServeShowCard"></ShowCard>
    </div>
    <div class="achievement center">
      <h4 class="descH4">建设成果</h4>
      <span class="line"></span>
      <p
        style="
          font-size: 16px;
          font-weight: 400;
          color: #222;
          line-height: 28px;
          width: 1100px;
          text-align: center;
          margin: 20px 0;
        "
      >
        基于山东大学“一校三地”的办学规格，采用“微应用+大平台”的架构，用统一的平台融合不同校区的差异化管理机制，建设了“一校三地”统一的人力资源管理与服务平台。
      </p>
      <img
        src="@/assets/TypicalCase/sandong/achievement.png"
        alt=""
        style="width: 1100px"
      />
      <ul class="achievementDesc">
        <li>
          <img src="@/assets/TypicalCase/zhongsan/one.png" alt="" />
          <div class="achievementDescRight">
            <h5>数据层面</h5>
            <p>
              建立了“一校三地”统一的教职工数据中心，并梳理和整合了人事相关数据，按“一数一源一责”的原则明确数据采集和维护机制，保障和提升了数据质量。
            </p>
          </div>
        </li>
        <li>
          <img src="@/assets/TypicalCase/zhongsan/two.png" alt="" />
          <div class="achievementDescRight">
            <h5>业务层面</h5>
            <p>
              按部门职责梳理业务管理流程，实现业务对接与协同、以教职工为中心提供更加丰富、便捷的服务功能和应用。最大程度的实现了业务办理的网络化、自动化和服务提供的自助化、移动化，把“人”从事务中解放出来，提高了工作效率。
            </p>
          </div>
        </li>
        <li>
          <img src="@/assets/TypicalCase/zhongsan/three.png" alt="" />
          <div class="achievementDescRight">
            <h5>服务层面</h5>
            <p>为教职工提供了不同的服务入口，提升了教职工的体验感和获得感。</p>
          </div>
        </li>
      </ul>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
export default {
  name: 'SanDong',
  data () {
    return {
      constructionDesc: [
        {
          src: require('@/assets/TypicalCase/zhongsan/one.png'),
          title: '办学格局的挑战',
          desc: [
            {
              p: '校区分散在不同城市，形成了一校三地（济南、威海、青岛）的办学格局。'
            }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/two.png'),
          title: '机制层面的挑战',
          desc: [
            {
              p: '不符合信息化要求，但积累多年的制度政策及业务流程需要被突破。'
            }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/three.png'),
          title: '观念层面的挑战',
          desc: [
            {
              p: '信息化的推进将影响现有业务的习惯性做法，对部门及个人的工作形式将提出新的要求。'
            }
          ]
        }
      ],
      step: {
        title: '总体规划',
        p: '山东大学人力资源信息化建设始于2018年，以山东大学智慧校园建设的整体规划为指导，“一校三地”统一规划，充分融合现有资源，提升服务与管理效率。项目聚焦于教职工服务、教职工管理、教职工发展、人才队伍建设决策和教职工数据管理等五个层面的“一体化”。',
        content: [
          {
            title: '提升服务响应能力',
            desc: '强调以人为本，面向不同角色、不同业务、不同场景构建人性化的服务和应用，提升多端异屏体验的友好性、一致性。',
            img: require('@/assets/TypicalCase/sandong/xiangying.png'),
            position: 'top:10;left:0'
          },
          {
            title: '提升管理支持能力',
            desc: '通过构建统一的平台，消除数据孤岛、突破部门壁垒，实现各部门协同办公。',
            img: require('@/assets/TypicalCase/sandong/zhichi.png'),
            position: 'top:0;right:0'
          },
          {
            title: '提升决策支持能力',
            desc: '通过数据挖掘与分析技术，在人才队伍建设、绩效评估、薪酬激励、杰出人才引进关键业务方面洞悉趋势、发现问题与机会，辅助领导决策。',
            img: require('@/assets/TypicalCase/sandong/juece.png'),
            position: 'top:190px;left:0'
          },
          {
            title: '提升数据管理能力',
            desc: '基于统一的数据治理标准，实现教职工数据的统一采集、维护、共享和展现，明确数据维护和管理责任，发挥数据的价值。',
            img: require('@/assets/TypicalCase/zhongsan/data.png'),
            position: 'top:190px;right:0'
          }
        ]
      }
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moCooperation/moSanDong')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped>
.sanDongContainer {
  padding-top: 80px;
  min-width: 1200px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 360px;
  background: url("~@/assets/TypicalCase/sandong/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}
.schoolDesc {
  width: 100%;
  height: 484px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.construction {
  width: 100%;
  height: 552px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
}
.constructionDesc {
  width: 1200px;
}

.step {
  width: 100%;
  height: 628px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
}
.technicalServeShowCard {
  height: 100%;
}
::v-deep .technicalServeShowCard .showCardContent .left img {
  width: 68px;
  height: 68px;
}
::v-deep .technicalServeShowCard .showCardContent .right {
  width: 432px;
}
::v-deep .technicalServeShowCard .showCardContent .right p {
  font-size: 16px;
}
.achievement {
  width: 100%;
  height: 1162px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
}
.achievementDesc {
  width: 1100px;
  padding-top: 40px;
}
.achievementDesc li {
  display: flex;
  margin-bottom: 40px;
}
.achievementDesc li img {
  width: 68px;
  height: 68px;
}
.achievementDescRight {
  margin-left: 20px;
}
.achievementDescRight h5 {
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 10px;
}
.achievementDescRight p {
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 24px;
}
.mianbaoxie{
  width: 1200px;
  margin-top: 20px;
}
</style>
